import VariablesListModal from './variables_list_modal.vue'
import pubSub from '@/services/epic_pub_sub.js'
import { createVueApp } from '@/vue_components/create_vue_app'

export default class {
  constructor (params = {}) {
    this.uniqID = this._generateID()
    this.modal = this._init(params)
    this._subscribe(params)
    this._list = []
    if (params.variables) this.setList(params.variables)
  }

  _generateID () {
    return `f${(+new Date()).toString(16)}`
  }

  _init (params) {
    let container = document.getElementById('variables_list_modal_container')
    if (!container) {
      container = document.createElement('div')
      container.id = 'variables_list_modal_container'
      window.document.body.appendChild(container)
    }

    return createVueApp({
      el: '#variables_list_modal_container',
      name: 'VariablesListModalApp',
      render: (h) => h(VariablesListModal, {
        props: {
          id: this.uniqID,
          labelMethod: params.labelMethod,
        },
      }),
      useChildren: true,
    })
  }

  _subscribe (params) {
    pubSub.subscribe(`VARIABLES_LIST_MODAL.${this.uniqID}.ITEM_SELECTED`, (object) => {
      if (typeof params.onItemSelected === 'function') {
        params.onItemSelected(object)
        Notificator.success(T.variable_added)
      }
    })
  }

  _filterList (list) {
    return list.filter((item) => {
      let res = true
      switch (typeof item.if) {
        case 'function':
          res = item.if()
          break
        case 'boolean':
          res = item.if
      }
      if (item.children && res) item.children = this._filterList(item.children)

      return res
    })
  }

  setList (list = []) {
    list = this._filterList(list)
    pubSub.emitAsync(`VARIABLES_LIST_MODAL.${this.uniqID}.SET_LIST`, [...list])
    this._list = list
  }

  show () {
    pubSub.emitAsync(`VARIABLES_LIST_MODAL.${this.uniqID}.SHOW`)
  }

  hide () {
    pubSub.emitAsync(`VARIABLES_LIST_MODAL.${this.uniqID}.HIDE`)
  }

  getList () {
    return this._list
  }
}

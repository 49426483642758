<template>
  <div :id="`variables_list_modal-${id}`">
    <modal
      v-if="visible"
      :modal-visibility="visible"
      :modal-class="'custom_message_editor_modal'"
      @close="hide"
    >
      <div slot="header">
        {{ `${T.choose_variable}` }}
      </div>
      <div slot="body">
        <el-tree
          :data="variables"
          @node-click="handleNodeClick"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/vue_components/modal.vue'

export default {
  name: 'VariablesListModal',
  components: {
    Modal,
  },
  props: {
    labelMethod: {
      type: Function,
      default: null,
    },
    id: {
      type: String,
      default: 'message_editor_modal',
    },
  },
  data () {
    return {
      variables: [],
      visible: false,
    }
  },
  created () {
    this.$pubSub.subscribe(
      `VARIABLES_LIST_MODAL.${this.id}.SET_LIST`,
      (variables) => {
        if (this.labelMethod) {
          this.formatVariables(variables)
        }
        this.variables = variables
      }
    )

    this.$pubSub.subscribe(
      `VARIABLES_LIST_MODAL.${this.id}.SHOW`,
      this.show
    )

    this.$pubSub.subscribe(
      `VARIABLES_LIST_MODAL.${this.id}.HIDE`,
      this.hide
    )
  },
  methods: {
    formatVariables (variables) {
      for (const variable of variables) {
        variable.label = this.labelMethod(variable.source)
        if (variable.children) this.formatVariables(variable.children)
      }
    },
    show () {
      this.visible = true
    },
    hide () {
      this.visible = false
    },
    getParents (node, acc = []) {
      if (node.level === 0) {
        return acc
      }
      acc.unshift(node)

      return this.getParents(node.parent, acc)
    },
    handleNodeClick (obj, node) {
      if (!node.isLeaf) return
      const chain = this.getParents(node)
      const object = {
        source: chain.map((n) => n.data.source),
        label: chain.map((n) => n.data.label),
      }
      this.$pubSub.emitAsync(`VARIABLES_LIST_MODAL.${this.id}.ITEM_SELECTED`, object)
    },
  },
}
</script>
